import parse from 'html-react-parser'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useRef } from 'react'

type Props = {
  htmlPage: {
    body: string
    head?: string
    scripts?: { src: string; async: boolean; inlineJs: string }[]
  }
}

const StaticPageContainer = ({ htmlPage }: Props) => {
  const staticContentRef = useRef<HTMLDivElement>(null)

  const router = useRouter()

  useEffect(() => {
    const handleRouteChangeComplete = (_: string, { shallow }: { shallow: boolean }) => {
      if (shallow) return
      if (typeof window.lazySizes === 'object') window.lazySizes.init()
      if (typeof window.cloudimgResponsive === 'object') window.cloudimgResponsive.process()
      window.dispatchEvent(new Event('load'))
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router.events])

  useEffect(() => {
    const loadScripts = (scripts: { src: string; async: boolean; inlineJs: string }[]) => {
      const scriptPromises = scripts.map((script) => {
        return new Promise<void>((resolve, reject) => {
          const scriptElement = document.createElement('script')
          if (script.src) scriptElement.src = script.src
          if (script.inlineJs) {
            scriptElement.innerHTML = script.inlineJs
            resolve()
          }
          scriptElement.onload = () => resolve()
          scriptElement.onerror = () => reject()
          document.body.appendChild(scriptElement)
        })
      })

      return Promise.all(scriptPromises)
    }

    const loadAllScripts = async () => {
      const blockingScripts =
        htmlPage.scripts?.filter((script) => !script.async && script.src) || []
      const remainingScripts =
        htmlPage.scripts?.filter((script) => script.async || script.inlineJs) || []

      // Check if scripts are added, but not loaded yet(useEffect is run twice on Draft view)
      if (document.querySelector(`script[src*="${blockingScripts[0].src}"]`)) return

      await loadScripts(blockingScripts)
      await loadScripts(remainingScripts)
    }
    if (htmlPage.scripts && typeof window.jQuery !== 'function') {
      staticContentRef.current?.querySelectorAll('script').forEach((script) => {
        script.remove()
      })
      loadAllScripts().then(() => {
        // Video players are not loading on the first render
        window.dispatchEvent(new Event('load'))
      })
    }
  }, [htmlPage.scripts])

  return (
    <>
      <Head>
        {/* Move static head content to the main <head> handled by nextjs */}
        {htmlPage.head ? <>{(parse(htmlPage.head) as JSX.Element).props.children}</> : null}
      </Head>

      {/* Intercept GetPageArtworkOverlayItem call */}
      <Script id="GetPageArtworkOverlayItem">
        {`
          function GetPageArtworkOverlayItem(id, Inquiry, isOverlay, currentArtwork, fromApiError) {
            const event = new CustomEvent("open-inquire-popup", { detail: { id, Inquiry, isOverlay, currentArtwork }});
            document.dispatchEvent(event);
          }
        `}
      </Script>

      {/* Intercept GetCTAItem call */}
      <Script id="GetCTAItem">
        {`
          function GetCTAItem(title, id, Inquiry, currentCTA) {
            const event = new CustomEvent("open-inquire-popup", { detail: { id, Inquiry, isOverlay: false, currentCTA }});
            document.dispatchEvent(event);
          }
        `}
      </Script>

      {/* Intercept GetArtworkOverlayItem call */}
      <Script id="GetArtworkOverlayItem">
        {`
          function GetArtworkOverlayItem(id, Inquiry, isOverlay, currentArtwork) {
            const event = new CustomEvent("open-artwork-overlay", { detail: { id, Inquiry, isOverlay, currentArtwork }});
            document.dispatchEvent(event);
          }
      `}
      </Script>

      {/* Intercept .subscribe-button-block click and open subscribe modal */}
      <Script id="SubscribeButtonBlock">
        {`
          document.querySelectorAll(".subscribe-button-block").forEach(function(element) {
            element.addEventListener("click", function() {
              const event = new CustomEvent("open-subscribe-popup");
              document.dispatchEvent(event);
            });
          });
        `}
      </Script>

      <div
        dangerouslySetInnerHTML={{ __html: htmlPage.body }}
        suppressHydrationWarning
        ref={staticContentRef}
      />
    </>
  )
}

export default StaticPageContainer
